import React, {Fragment, useState} from 'react';
import { Container, Button, Image, Spinner } from 'react-bootstrap';
import { ExecuteSql, GetRows } from './api';
import SqlTable from './SqlTable';
import RefreshImage from '../assets/refresh.png';

function Home() {
    const [ Mkter, SetMkter] = useState(null);
    const [ MktSet, SetMktSet ] = useState(null);

    const ClickedMkter = (row) => {
        //console.log(row);
        SetMkter(row);
    }

    function refresh() {
        window.location.reload();
    }

    const ClickedMktSet = (row ) => {
        //console.log(row);
        SetMktSet(row);
    }

    return (
        <React.Fragment>
            <Container>
                <div className='space_between'>
                    <span>마케터</span>
                    <Image
                        src={RefreshImage}
                        width={25}
                        height={25}
                        style={{marginLeft:10, cursor: 'pointer'}}   
                        onClick={refresh}                        
                    />
                </div>
                <SqlTable
                    sql = {`
                        SELECT m.mkt_user_id, m.user_id, u.last_login_type, u.user_name, u.phone_number,m.checked_admin, m.create_at
                        FROM t_mkt_user m
                        JOIN t_user u ON m.user_id = u.user_id;
                    `}
                    column_names={{
                        mkt_user_id : 'ID',
                        user_id : 'User',
                        checked_admin : '관리자',
                        user_name : '이름',
                        phone_number : 'Phone',
                        last_login_type : 'Socail'
                    }}
                    skip_columns={[
                        'mkt_user_id'   
                    ]}
                    // do_paging = {true}
                    onRowClick = { (row) => ClickedMkter(row)}
                />
            </Container>
            { Mkter && (
                <React.Fragment>
                    <Container style={{marginTop:20}} key={Mkter.mkt_user_id}>
                        <span>{`${Mkter.user_name}(${Mkter.last_login_type}) 홍보셋`}</span>
                        <SqlTable
                            sql = {`
                            SELECT 
                                ms.mkt_set_id,
                                concat('https://myshott.co.kr/mkt/', ms.mkt_code) as '타겟 URL',
                                ms.set_subject, 
                                concat('액세스(', ms.access_cnt, '), 로그인(', COUNT(DISTINCT mi.user_id), 
                                '), 실명인증(', COUNT(DISTINCT mi.phone_number), ')') as cnt,
                                ms.create_at
                            FROM 
                                t_mkt_set ms
                            LEFT JOIN 
                                t_mkt_invites mi ON ms.mkt_set_id = mi.mkt_set_id
                            WHERE 
                                ms.mkt_user_id = ${Mkter.mkt_user_id}
                            GROUP BY 
                                ms.mkt_set_id;
                            `}
                            column_names={{
                                mkt_set_id : 'ID',
                                set_subject : '제목'
                            }}
                            skip_columns={[
                                'mkt_set_id',
                                'mkt_user_id'
                            ]}
                            onRowClick = { (row) => ClickedMktSet(row)}
                        />                        
                    </Container>
                </React.Fragment>
            )}
            { MktSet && (
                <React.Fragment>
                    <Container style={{marginTop:20}} key={MktSet.mkt_set_id}>
                        <span>{`홍보 URLs`}</span>
                        <SqlTable
                            sql = {`
                                select * from t_mkt_url where mkt_set_id = ${MktSet.mkt_set_id}
                            `}
                            column_names={{
                                mkt_url : '홍보 URL',
                            }}                           
                            skip_columns={[
                                'url_id',
                                'mkt_set_id'
                            ]}                           
                        />
                    </Container>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default Home;