import React, {useState} from 'react';
import { Container, Button, Badge, Row, Col, Spinner } from 'react-bootstrap';
import SqlTable from '../SqlTable';
import { ExecuteSql, GetRows } from '../api';
import { useModal } from '../ShowModal';

function ManagerUser() {
    const [ selectedRow, SetSelectedRow] = useState(null);
    const { showModal} = useModal();
    const [ mktDeleting, setMktDeleting ] = useState(false);

    const ClickedRow = (row) => {
        //console.log(row);
        SetSelectedRow(row);
    }

    const SetCheckedAdmin = async () => {
        try {
            let checked_admin;
            if( selectedRow.checked_admin === 1) checked_admin = 'false';
            else checked_admin = 'true';
            const sql = `update t_mkt_user set checked_admin=${checked_admin} where user_id=${selectedRow.user_id}`;
            //console.log(sql);
            //const ret = await ExecuteSql('update t_mkt_user set checked_admin=? where mkt_user_id=?', [checked_admin, selectedRow.mkt_user_id]);
            const ret = await ExecuteSql(sql);
            if( ret.success ) {
                window.location.reload();
            }
        } catch(e) {
            console.log(e);
        }
    }

    async function _DeleteMkt() {
        setMktDeleting(true);
        try {
            console.log(selectedRow);
            const ret = await GetRows('select mkt_set_id from t_mkt_set where mkt_user_id=?', [selectedRow.mkt_user_id]);
            if( ret.success ) {
                for(const row of ret.data) {
                    await ExecuteSql('delete from t_mkt_invites where mkt_set_id=?', [row.mkt_set_id]);
                }
            }
            await ExecuteSql('delete from t_mkt_set where mkt_user_id=?', [selectedRow.mkt_user_id]);
            await ExecuteSql('delete from t_mkt_content where mkt_user_id=?', [selectedRow.mkt_user_id]);
            await ExecuteSql('delete from t_mkt_user where mkt_user_id=?', [selectedRow.mkt_user_id]);
            await ExecuteSql('update t_user set checked_mkt=false where user_id=?', [selectedRow.user_id]);
            window.location.reload();
        } catch(e) {
            console.log(e);
        }
        setMktDeleting(false);       
    }

    function DeleteMkt() {
        showModal({
            title : '마케터 삭제',
            children : `${selectedRow.user_name} 마케터를 삭제 하겠습니까?`,
            afterOK : () => _DeleteMkt()
        });
    }

    return (
        <React.Fragment>
            <Container style={{marginBottom : 10}}>
                <SqlTable
                    sql = {`
                        SELECT m.mkt_user_id, m.user_id, u.last_login_type, u.user_name, u.phone_number,m.checked_admin, m.create_at
                        FROM t_mkt_user m
                        JOIN t_user u ON m.user_id = u.user_id;
                    `}
                    column_names={{
                        mkt_user_id : 'ID',
                        user_id : 'User',
                        checked_admin : '관리자',
                        user_name : '이름',
                        phone_number : 'Phone',
                        last_login_type : 'Socail'
                    }}
                    skip_columns={[
                        'mkt_user_id'   
                    ]}
                    // do_paging = {true}
                    onRowClick = { (row) => ClickedRow(row)}
                />
            </Container>
            { selectedRow && (
                <React.Fragment>
                    { mktDeleting && (
                        <Container className="d-flex flex-column align-items-center justify-content-center" style={{marginBottom:10}}>
                            <Spinner animation="border" role="status" style={{marginBottom:20}}>
                                <span className="visually-hidden">삭제중...</span>
                            </Spinner> 
                        </Container>   
                    )}
                    <Container>
                        <Row>
                            <Col className='dark_mode'>{selectedRow.user_name}</Col>
                            <Col className='dark_mode'>{selectedRow.phone_number}</Col>
                            <Col >
                                <Button variant="success btn-sm" className="custom-login-btn" onClick={SetCheckedAdmin}>
                                    {selectedRow.checked_admin === 1 ? '관리자 해제' : '관리자 지정'}
                                </Button>
                            </Col>
                            <Col >
                                <Button variant="danger btn-sm" className="custom-login-btn" onClick={DeleteMkt}>
                                    {'마케터 삭제'}
                                </Button>
                            </Col>                      
                        </Row>
                    </Container>                                   
                </React.Fragment>
            )}
        </React.Fragment>
    );    
}

export default ManagerUser;