import React, {useState, useEffect, useRef} from 'react';
import { Container, Stack, Spinner, Image, } from 'react-bootstrap';
import { GetRows, ExecuteSql } from '../api';
import AddImage from '../../assets/add.png';
import { useEnv } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../AlertMessage';
import { formatDate, truncateString } from '../Global';
import SubMenu from '../../assets/dots.png';
import EditImage from '../../assets/draw.png';
import DeleteImage from '../../assets/delete.png';
import { useModal } from '../ShowModal';

function PostHome() {
    const { ENV } = useEnv();
    const navigate = useNavigate();
    const [ rows, setRows ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const { showModal } = useModal();

    const [showMenu, setShowMenu] = useState(false); // 메뉴 표시 여부
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 }); // 메뉴 위치
    const [currentRow, setCurrentRow] = useState(null); // 현재 메뉴 관련 row 데이터
    const menuRef = useRef(null); // 메뉴 창 참조

    const handleSubMenuClick = (event, row) => {
        const rect = event.target.getBoundingClientRect();
        setMenuPosition({ x: rect.x, y: rect.y + rect.height });
        
        // 만약 메뉴가 이미 표시되어 있고 동일한 row를 클릭한 경우, 메뉴를 닫습니다.
        if (showMenu && currentRow === row) {
            setShowMenu(false);
        } else {
            setShowMenu(true); // 메뉴를 표시
            setCurrentRow(row); // 현재 row 설정
        }
        
        event.stopPropagation(); // 이벤트 버블링을 막음
    };

    // 클릭 이벤트 리스너 함수
    const handleOutsideClick = (event) => {
        if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false); // 메뉴 외부 클릭 시 메뉴 닫기
        }
    };

    // 컴포넌트 마운트 및 언마운트 시에 이벤트 리스너 추가/제거
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showMenu]);    

    // 메뉴 항목 클릭 처리
    const handleMenuItemClick = (action) => {
        if (currentRow) {
            //console.log(`${action} clicked for row:`, currentRow);
            // 여기서 currentRow를 사용하여 특정 작업 수행
            if(action === 'Edit') {
                postEdit(currentRow);
            } else if( action === 'Delete') {
                showModal({
                    title : '다음 글을 삭제하겠습니까?',
                    children : `제목 : [${truncateString(currentRow.mkt_subject, 25)}]`,
                    afterOK : () => postDelete(currentRow.content_id)
                });
            }
        }
        setShowMenu(false); // 메뉴 항목 클릭 시 메뉴 닫기
    };

    useEffect(() => {
        if( ENV?.mkt_user_id) 
            LoadPosts();        
    }, [ENV?.mkt_user_id]);       
    
    if( ! ENV?.mkt_user_id) {
        return (
            <Container>
                <AlertMessage
                    title='인증 실패'
                >
                    먼저 마케팅 회원 인증을 받고 진행 하세요
                </AlertMessage>
            </Container>
        );
    }

    async function LoadPosts() {
        setIsLoading(true);
        try {
            const ret = await GetRows('select * from t_mkt_content where mkt_user_id=? order by updated_at desc', [ENV.mkt_user_id]);
            if( ret.success ) {
                //console.log(ret.data);
                setRows(ret.data);
            }
        } catch (error) {
            console.log(error);
        } 
        setIsLoading(false);
    }

    const postAdd = () =>  {
        navigate('/post_edit');
    }

    const postEdit = (row) => {
        navigate('/post_edit', { state : {data : row}})
    }

    const postview = (row) => {
        navigate('/post_view', { state : {data : row}})
    }

    const postDelete = async (content_id) => {
        try {
            const ret = await ExecuteSql('delete from t_mkt_content where content_id=?', [content_id]);
            if( ret.success ) {
                LoadPosts();
            }
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <Container>
            <div className='flex_right'>
                <Image 
                    src={AddImage}
                    roundedCircle
                    width={30}
                    height={30}
                    onClick={postAdd} style={{ cursor: 'pointer' }}
                />
            </div>
            { isLoading && (
                <Container className="d-flex flex-column align-items-center justify-content-center" style={{marginBottom:10}}>
                    <Spinner animation="border" role="status" style={{marginBottom:20}}>
                        <span className="visually-hidden">읽는중...</span>
                    </Spinner> 
                </Container>   
            )}
            { rows.length === 0 && (
                <Container className='flex_center'>
                    <div className=''>
                        홍보글이 존재하지 않습니다. 작성하려면 오른쪽 + 버튼을 클릭하세요
                    </div>
                </Container>
            )}
            <Container style={{marginTop:30}}>
                <Stack gap={3}>
                    { rows.map((row, rowIndex) => (
                        <React.Fragment>
                        <div>
                            <div className="mb-3 d-flex justify-content-between" key={rowIndex}>
                                <div key={rowIndex} style={{ cursor: 'pointer' }} onClick={() => postview(row)}>
                                    {row.mkt_subject}
                                </div>
                                <Image
                                    src={SubMenu}
                                    width={20}
                                    height={20}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => handleSubMenuClick(e, row)} // 메뉴 클릭 이벤트에 row 추가
                                />
                            </div>
                            <span className='mainSmallText'>
                                {formatDate(row.updated_at)}
                            </span>
                            <hr/>              
                        </div>
                        </React.Fragment>
                    ))}
                </Stack>
            </Container>
            {showMenu && (
                <div 
                    ref={menuRef} // 메뉴 창 참조 설정
                    className='bordered-container'
                    style={{
                        position: 'absolute',
                        top: `${menuPosition.y}px`,
                        left: `${menuPosition.x}px`,
                        zIndex: 1000,
                        // 기타 스타일링: 배경색, 테두리 등
                        borderRadius: 10,
                        padding: 15
                    }}
                >
                    <div className='flex_row' onClick={() => handleMenuItemClick('Edit')} style={{marginBottom:10, cursor: 'pointer'}}>
                        <Image
                            src={EditImage}
                            width={25}
                            height={25}
                            style={{marginRight: 10}}
                        />
                        <span>편집</span>
                    </div>
                    <div className='flex_row' onClick={() => handleMenuItemClick('Delete')} style={{cursor: 'pointer'}}>
                        <Image
                            src={DeleteImage}
                            width={25}
                            height={25}
                            style={{marginRight: 10}}
                        />
                        <span>삭제</span>
                    </div>                   
                </div>
            )}           
        </Container>
    );
}

export default PostHome;
