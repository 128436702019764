import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const HoverImage = ({ src, alt, width, height, style, onClick, extraArgs = []}) => {
    const [isHovered, setIsHovered] = useState(false);

    // 사용자로부터 받은 스타일과 내부에서 정의한 스타일을 결합합니다.
    const combinedStyle = {
        width: width+5,
        height: height+5,
        ...style // 사용자 정의 스타일을 스프레드 연산자를 통해 추가합니다.
    };  

    // onClick을 처리하는 래퍼 함수
    const handleClick = (event) => {
        if (onClick) {
            onClick(event, ...extraArgs);  // onClick 함수에 이벤트와 추가 인자들을 전달
        }
    };    

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{combinedStyle}} // 이 부분은 이미지 크기에 맞게 조정할 수 있습니다.
        >
            {isHovered ? (
                <Image src={src} width={width} height={height} alt={alt} onClick={handleClick} />
            ) : (
                <div style={{ width: width, height: height }}></div>
                // 마우스가 이미지 위에 없을 때 보여질 내용. 여기서는 배경색만 있는 빈 상자를 표시합니다.
            )}
        </div>
    );
};

export default HoverImage;