import Axios from 'axios';
import { routerServer } from './Global.js';

const fetchDataFromDB = async (url) => {
  try {
    const response = await Axios.get(url);
    if (response.status === 200) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data };
    }
  } catch (error) {
    // 네트워크 오류나 서버에서 오류 응답을 보낸 경우
    if (error.response) {
      // 서버가 응답한 경우 (예: HTTP 상태 코드 400, 500)
      return { success: false, error: error.response.data };
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      return { success: false, error: "No response received from server" };
    } else {
      // 요청을 만드는 중 오류 발생
      return { success: false, error: error.toString() };
    }
  }
};

const saveDataToDB = async (url, saveData) => {
  try {
    const response = await Axios.post(url, saveData);
    if (response.status === 201) {
      return { success: true, data: response.data };
    } else {
      return { success: false, error: response.data };
    }
  } catch (error) {
    // 네트워크 오류나 서버에서 오류 응답을 보낸 경우
    if (error.response) {
      // 서버가 응답한 경우 (예: HTTP 상태 코드 400, 500)
      return { success: false, error: error.response.data };
    } else if (error.request) {
      // 요청이 이루어졌으나 응답을 받지 못한 경우
      return { success: false, error: "No response received from server" };
    } else {
      // 요청을 만드는 중 오류 발생
      return { success: false, error: error.toString() };
    }
  }
};

export const GetRow = async (sql, params=null) => {
  const postData = {
    sql : sql,
    params : params
  };
  const url = `${routerServer}/eva/get_row`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const GetRows = async (sql, params=null) => {
  const postData = {
    sql : sql,
    params : params
  };
  const url = `${routerServer}/eva/get_rows`;
  //console.log(url);
  return await saveDataToDB(url, postData);      
};

export const ExecuteSql = async (sql, params=null) => {
    const postData = {
      sql : sql,
      params : params
    };
    const url = `${routerServer}/eva/execute_sql`;
    //console.log(url);
    return await saveDataToDB(url, postData);      
  };
  
  export const GetRows2 = async (sql, params=null) => {
    const postData = {
      sql : sql,
      params : params
    };
    const url = `${routerServer}/eva/get_rows2`;
    //console.log(url);
    return await saveDataToDB(url, postData);      
  };
  
