import React, { useEffect, useState } from 'react';
import { Pagination , Table } from 'react-bootstrap';
import { GetRows2 } from './api';
import { formatDate } from './Global';

// 필드명을 변경하기 위한 매핑
// const column_names = {
//     originalFieldName1: '새 필드명 1',
//     originalFieldName2: '새 필드명 2',
//     ...
// };

/*
columnType ----------
VARCHAR: 253,
INT : 3,
BOOLEAN : 1,
DATE : 10,
DATETIME : 12,
TIMESTAMP: 7,
TIME : 7
-------------------------
*/
function SqlTable({sql, params, column_names, skip_columns, do_paging=false, onRowClick}) {
    const [rows, setRows] = useState([]);
    const [fields, setFields] = useState([]);
    const [ skipIndex, setSkipIndex] = useState([]);

    useEffect(() => {
        // async 함수를 정의하고 즉시 호출합니다.
        (async () => {
            try {
                setRows([]);
                const ret = await GetRows2(sql, params ? params : null);
                if( ret.success ) {
                    //console.log(ret.data);

                    const _fields = [];
                    const _skip = [];
                    ret.data.fields.forEach((field, idx) => {
                        if (skip_columns?.includes(field.name)) {
                            _skip.push(idx);
                        }
                        const _field = {
                            name: column_names?.[field.name] || field.name, // 매핑된 새 필드명 또는 기존 필드명 사용
                            type: field.type
                        };
                        _fields.push(_field);
                    });
                    setSkipIndex(_skip);
                    //console.log(_skip);

                    // const _rows = [];
                    // for(const row of ret.data.rows) {
                    //     let _row = {};
                    //     Object.entries(row).forEach(([key, value]) => {
                    //         if (!skip_columns?.includes(key)) {
                    //             _row[key] = value;
                    //         }
                    //     });
                    //     _rows.push(_row);
                    // }
                    setRows(ret.data.rows);
                    setFields(_fields);
                } else {
                    console.log(ret.error);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);    

    function DynamicTableHeader({ fields }) {
        return (
          <thead>
            <tr>
              {/* <th>#</th> */}
              { fields
                .filter((_, valueIndex) => !skipIndex.includes(valueIndex))
                .map((field, index) => (
                <th key={index}>{field.name}</th> // 여기서 field.name을 동적으로 생성
              ))}
            </tr>
          </thead>
        );
    }

    function isDateTimeType(columnType) {
        // 날짜시간 관련 타입의 columnType 값 목록
        const dateTimeTypes = [7, 10, 11, 12];
        return dateTimeTypes.includes(columnType);
    }   

    function PrintValue({value, index}) {
        // 컬럼 타입이 'datetime'일 경우, 지정된 포맷으로 날짜를 변환합니다.'
        if (isDateTimeType(fields[index].type)) {
            // 포맷을 원하는 대로 조정할 수 있습니다.
            //return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
            return (
                <React.Fragment>
                    {formatDate(value)}
                </React.Fragment>            
            );
        } else if(fields[index].type === 1) {
            if( value === 1 ) {
                return (
                    <span className='emphasisText'>TRUE</span>
                );
            } else {
                return(
                    <span>FALSE</span>
                );
            }
        }
        // 날짜시간 타입이 아닌 경우, 값을 그대로 반환합니다.
        return (
            <React.Fragment>
                {value}
            </React.Fragment>
        );
    }

    function DynamicTableBody({ data }) {
        //console.log(skipIndex);
        return (
            <tbody>
            {data.map((row, rowIndex) => (
                <tr key={rowIndex} onClick={() => onRowClick ? onRowClick(row) : null} style={{ cursor: 'pointer' }}>
                {Object.values(row).map((value, valueIndex) => {
                    // 현재 valueIndex가 건너뛸 리스트에 없다면 PrintValue 컴포넌트 렌더링
                    if (!skipIndex.includes(valueIndex)) {
                        return <td key={valueIndex}><PrintValue value={value} index={valueIndex}/></td>;
                    }
                    // 건너뛰고자 하는 valueIndex에 해당하면 null 반환 (렌더링하지 않음)
                    return null;
                })}
                </tr>
            ))}
            </tbody>
        );
    }
      
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    // 현재 페이지 데이터 계산
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = rows.slice(indexOfFirstRow, indexOfLastRow);
    
    // 페이지네이션을 위한 총 페이지 수
    const totalPages = Math.ceil(rows.length / rowsPerPage);    
    function PaginationComponent({ totalPages, paginate }) {
        let active = currentPage;
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
          items.push(
            <Pagination.Item key={number} active={number === active} onClick={() => paginate(number)}>
              {number}
            </Pagination.Item>,
          );
        }
      
        return (
          <Pagination>{items}</Pagination>
        );
    }

    return (
        <React.Fragment>
            <Table striped bordered hover>
                <DynamicTableHeader fields={fields} />
                { do_paging ?
                    <DynamicTableBody data={currentRows}/> :
                    <DynamicTableBody data={rows}/>
                }       
            </Table>
            {
                do_paging && (
                    <div className='flex_center'>
                        <PaginationComponent totalPages={totalPages} paginate={setCurrentPage} />
                    </div>
                )
            }   
        </React.Fragment>
    );    
}

export default SqlTable;