import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Image, Badge } from 'react-bootstrap';
import PrevImage from '../../assets/prev.png'
import parse from 'html-react-parser';
import { useEnv } from '../AppContext';
import { GetRow } from '../api';
import { formatDate } from '../Global';

function PostView() {
    const location = useLocation();
    const [ isHtml , setIsHtml ] = useState(false);
    const navigate = useNavigate();
    const { ENV, setEnv } = useEnv();
    const [ data, setData] = useState(location.state?.data)

    useEffect(() => {
        // 페이지가 변경될 때 실행될 로직
        //console.log('Page changed:', location);
        // location.state 또는 location.key 값을 사용하여 뒤로 가기를 통한 페이지 복귀 여부를 판단할 수 있음
        //console.log(ENV.post_edit_complete);

        if( ENV.post_edit_complete === true) {
            const newENV = { ...ENV };
            newENV.post_edit_complete = false;
            setEnv(newENV);             
            ReloadPost();
        }
        if( data?.mkt_content ) {
            const check = checkHtml(data.mkt_content);
            //console.log(check);
            setIsHtml(check);
        }        
    }, [location, data?.mkt_content, ENV.post_edit_complete]);   

    async function ReloadPost() {
        try {
            const ret = await GetRow('select * from t_mkt_content where content_id=?', data.content_id);
            if( ret.success ) {
                const newData = {...data};
                newData.mkt_subject = ret.data.mkt_subject;
                newData.mkt_content = ret.data.mkt_content;
                setData(newData);
            }
        } catch(e) {
            console.log(e);
        }
    }

    function checkHtml(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    }

    // function isHtml(content) {
    //     // HTML 태그를 찾는 간단한 정규 표현식
    //     const htmlPattern = /<[^>]+>/;
    //     return htmlPattern.test(content);
    // }

    // .container 스타일을 인라인 스타일로 변환
    const containerStyle = {
        display: 'flex',
        alignItems: 'center', // "아이템들을 컨테이너의 가운데에 정렬"
        justifyContent: 'space-between', // "아이템들 사이에 균등한 간격을 둠"
        width: '100%', // "혹은 부모 요소에 따라 조정"
    };

    // .text 스타일을 인라인 스타일로 변환
    const subjectStyle = {
        flexGrow: 1, // "나머지 공간을 모두 차지하도록 설정"
        margin: '0 20px', // "좌우 이미지와의 간격 조정"
    };

    function goBack() {
        navigate(-1);
    }

    function goEditor() {
        const newENV = { ...ENV };
        newENV.post_edit_complete = false;
        setEnv(newENV);         
        navigate('/post_edit', { state : {data : data}})
    }

    async function copyClipboard() {
        try {
            await navigator.clipboard.writeText(data.mkt_content);
            alert('클립보드에 복사되었습니다.');
        } catch (err) {
            console.error('클립보드 복사에 실패했습니다.', err);
        }
    }

    return (
        <Container >
            <div style={containerStyle}>
                <Image
                    src={PrevImage}
                    width={25}
                    height={25}
                    style={{ cursor: 'pointer' }}
                    onClick={goBack}
                />
                <div style={subjectStyle}>{data.mkt_subject}</div>
                <span style={{width: 50, cursor: 'pointer'}} onClick={goEditor}>편집</span>
            </div>
            <hr/>
            <div className='space_between' style={{marginBottom:15}}>
                <span className='mainSmallText' style={{flexGrow: 1}}>{formatDate(data.updated_at )}</span>
                <Badge pill bg="secondary" style={{cursor: 'pointer'}} onClick={copyClipboard}>내용복사</Badge>
            </div>
            { isHtml ? (
                <div>{parse(data.mkt_content)}</div>
            ) : (
                <pre className='mainDefaultText'>{data.mkt_content}</pre>
            )}
        </Container>
    );
}

export default PostView;
