import React, {useState, useEffect, useRef} from 'react';
import { Container, Stack, Spinner, Image, Form, InputGroup, Card, Button } from 'react-bootstrap';
import AddImage from '../../assets/add.png';
import { GetRow, GetRows, ExecuteSql } from '../api';
import { useEnv } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AlertMessage from '../AlertMessage';
import { formatDate } from '../Global';
import SubMenu from '../../assets/dots.png';
import EditImage from '../../assets/draw.png';
import DeleteImage from '../../assets/delete.png';
import { useModal } from '../ShowModal';
import CopyImage from '../../assets/copy.png';
import TrashImage from '../../assets/trash-bin.png';
import HoverImage from '../HoverImage';
import GoLinkImage from '../../assets/share.png';

const InputUrl = ({ mkt_set_id, rowIndex, title, baseUrl='', onDeleteClicked, onUrlChange }) => {
    const id = mkt_set_id;
    const index = rowIndex;
    const [url, setUrl] = useState(baseUrl);

    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        onUrlChange(newUrl); // 부모 컴포넌트로 변경된 URL 전달
    }; 

    return (
        <Card  style={{marginTop : 15}}>
            <Card.Header>{title}</Card.Header>
                <Card.Body>
                    <Form> {/* 여기에서 onSubmit 이벤트를 처리합니다. */}
                        <Form.Group controlId="formBasicUrl">
                            <div className='flex_row'>
                            <Form.Control 
                                type="text" 
                                placeholder="홍보 URL을 입력하세요" 
                                spellCheck="false"
                                style={{marginRight: 10}}
                                value={url}
                                onChange={handleUrlChange} // 입력 필드가 변경될 때 이벤트 핸들러 호출
                            />
                            <HoverImage
                                src={TrashImage}
                                width="25px"
                                height="25px"
                                alt = "항목삭제"
                                onClick={onDeleteClicked}
                            /> 
                            </div>
                        </Form.Group>
                    </Form>
            </Card.Body>
        </Card>
    );
};

const ListUrl = ({urls}) => {

    return (
        <div>
            { urls.map((row, rowIndex) => (
                <React.Fragment>
                <div key={`ListUrl - ${rowIndex}`}>
                    <InputUrl
                        title={`URL ${rowIndex}`}
                    />
                </div>
                </React.Fragment>
            ))}
        </div>
    );
}

function SetHome() {
    const { ENV } = useEnv();
    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ setSubject, SetSetSubject ] = useState('');
    const [ rows, setRows ] = useState([]);
    const { showModal } = useModal();
    const [ urlRows, setUrlRows] = useState({}); // mkt_set_id를 키로 가지는 객체
    const [ newUrlIndex, setNewUrlIndex ] = useState(null);
    const [ newUrl, setNewUrl] = useState('');

    const [showMenu, setShowMenu] = useState(false); // 메뉴 표시 여부
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 }); // 메뉴 위치
    const [currentRow, setCurrentRow] = useState(null); // 현재 메뉴 관련 row 데이터
    const menuRef = useRef(null); // 메뉴 창 참조

    const handleSubMenuClick = (event, row) => {
        const rect = event.target.getBoundingClientRect();
        setMenuPosition({ x: rect.x, y: rect.y + rect.height });
        
        // 만약 메뉴가 이미 표시되어 있고 동일한 row를 클릭한 경우, 메뉴를 닫습니다.
        if (showMenu && currentRow === row) {
            setShowMenu(false);
        } else {
            setShowMenu(true); // 메뉴를 표시
            setCurrentRow(row); // 현재 row 설정
        }
        
        event.stopPropagation(); // 이벤트 버블링을 막음
    };

    // 클릭 이벤트 리스너 함수
    const handleOutsideClick = (event) => {
        if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
            setShowMenu(false); // 메뉴 외부 클릭 시 메뉴 닫기
        }
    };

    // 컴포넌트 마운트 및 언마운트 시에 이벤트 리스너 추가/제거
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showMenu]);    

    // 메뉴 항목 클릭 처리
    const handleMenuItemClick = (action) => {
        if (currentRow) {
            //console.log(`${action} clicked for row:`, currentRow);
            // 여기서 currentRow를 사용하여 특정 작업 수행
            if(action === 'Edit') {
                setEdit(currentRow);
            } else if( action === 'Delete') {
                showModal({
                    title : '다음 홍보셋을 삭제하겠습니까?',
                    children : `https://myshott.co.kr/mkt/${currentRow.mkt_code}`,
                    afterOK : () => setDelete(currentRow.mkt_set_id)
                });
            }
        }
        setShowMenu(false); // 메뉴 항목 클릭 시 메뉴 닫기
    };    

    useEffect(() => {
        if( ENV?.mkt_user_id) 
            LoadMktSet();        
    }, [ENV?.mkt_user_id]);    

    if( ! ENV?.mkt_user_id) {
        return (
            <Container>
                <AlertMessage
                    title='인증 실패'
                >
                    먼저 마케팅 회원 인증을 받고 진행 하세요
                </AlertMessage>
            </Container>
        );
    }   

    const generateLinkCode = async () => {
        try {
            let mkt_code;
            while (true) {
                mkt_code = uuidv4().slice(0, 8);
                const ret = await GetRow('select count(*) as cnt from t_mkt_set where mkt_code=?', [mkt_code]);
                if (ret.success && ret.data.cnt === 0 ) {
                    break;
                }
            }
            return mkt_code;		
        } catch(e) {
            console.log(e);
        }
    };   

    const setAdd= async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const mkt_code = await generateLinkCode();
            const params = {
                mkt_user_id : ENV.mkt_user_id,
                mkt_code : mkt_code,
                set_subject : setSubject,
            }
            const ret = await ExecuteSql('insert into t_mkt_set set ?', params);
            if( ret.success ) {
                LoadMktSet();
            }
        } catch(e) {
            console.log(e);
        }
        setIsLoading(false);
    }

    async function LoadMktUrls(mkt_set_id) {
        try {
            const sql = 'select * from t_mkt_url where mkt_set_id=?';
            const ret = await GetRows(sql, [mkt_set_id]);
            if( ret.success ) {
                //newRow.mktUrls = ret3.data;
                if( ret.data.length>0) {
                    setUrlRows((prev) => ({ ...prev, [mkt_set_id]: ret.data }));
                } else {
                    setUrlRows((prev) => ({ ...prev, [mkt_set_id]: [] }));
                }
            }           
        } catch(e) {
            console.log(e);
        }
    }

    async function LoadMktSet() {
        setIsLoading(true);
        try {
            const ret = await GetRows('select * from t_mkt_set where mkt_user_id=? order by create_at desc', [ENV.mkt_user_id]);
            if ( ret.success) {
                //console.log(ret.data);
                const newData = [];
                const sql = `
                SELECT 
                    COUNT(phone_number) AS phone_number_count,
                    COUNT(user_id) AS user_id_count 
                FROM 
                    t_mkt_invites
                WHERE 
                    mkt_set_id = ?;
                `;

                for( const row of ret.data) {
                    let newRow = {...row};
                    newRow.phone_number_count = 0;
                    newRow.user_id_count = 0;
                    //newRow.mktUrls = [];
                    const ret2 = await GetRow(sql, [row.mkt_set_id]);
                    if( ret2.success) {
                        newRow.phone_number_count = ret2.data.phone_number_count;
                        newRow.user_id_count = ret2.data.user_id_count;
                    }
                    await LoadMktUrls(row.mkt_set_id);
                    newData.push(newRow);
                }
                setRows(newData);
            }
        } catch(e) {
            console.log(e);
        }
        setIsLoading(false);
    }

    const setDelete = async (mkt_set_id) => {
        try {
            const ret = await ExecuteSql('delete from t_mkt_set where mkt_set_id=?', [mkt_set_id]);
            if( ret.success ) {
                LoadMktSet();
            }
        } catch(e) {
            console.log(e);
        }
    }

    const setEdit = async (row) => {
    }   

    async function copyClipboard(mktUrl) {
        try {
            await navigator.clipboard.writeText(mktUrl);
            alert('클립보드에 복사되었습니다.');
        } catch (err) {
            console.error('클립보드 복사에 실패했습니다.', err);
        }
    }    

    const showNewUrl = (index) => {
        setNewUrlIndex(index);
    }

    const hideNewUrl = () => {
        setNewUrlIndex(null);
    }

    const handleNewUrlChange = (url) => {
        setNewUrl(url);
    }

    const validateUrl = (inputUrl) => {
        // 정규 표현식을 사용하여 HTTP 또는 HTTPS URL 확인
        const urlPattern = /^(https?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-\._~:/?#[\]@!$&'()*+,;=]+$/;
        return urlPattern.test(inputUrl);
    };

    const saveNewUrl  = async (mkt_set_id) => {
        try {
            //console.log(mkt_set_id, newUrl);
            if( newUrl === '') {
                showModal({
                    children : '빈 URL은 사용할 수 없습니다. 홍보 URL을 입력해주세요',
                    title : 'URL 입력 오류'
                });
                return;
            }
            if( !validateUrl(newUrl)) {
                showModal({
                    children : '잘못된 URL 형식입니다. 홍보 URL을 다시 입력해주세요',
                    title : 'URL 입력 오류'
                });               
                return;
            }
            const params = {
                mkt_set_id : mkt_set_id,
                mkt_url : newUrl
            }
            const ret = await ExecuteSql('insert into t_mkt_url set ?', params);
            if( ret.success ) {
                LoadMktUrls(mkt_set_id);
                setNewUrl('');
                setNewUrlIndex(null);
            }
        } catch (err) {
            console.log(err);
        }      
    } 
    
    const goUrl = (url) => {
        window.open(url, '_blank');
    }

    const _deleteUrl = async (mkt_set_id, url_id) => {
        try {
            //console.log(mkt_set_id, url_id);
            const ret = await ExecuteSql('delete from t_mkt_url where url_id=?', [url_id]);
            if( ret.success ) {
                LoadMktUrls(mkt_set_id);
            }
        } catch(e) {
            console.log(e);
        }       
    }

    const deleteUrl = (event, url, mkt_set_id, url_id) => {
        showModal({
            title : '홍보 URL  삭제',
            children : `[${url}]을 삭제 하겠습니까? `,
            afterOK : () => _deleteUrl(mkt_set_id, url_id)
        });
    }

    return (
        <Container>
            <Form>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">홍보셋제목</InputGroup.Text>
                    <Form.Control
                        type="text" 
                        required
                        placeholder="예) 네이버 블러그 마케팅 첫번째"
                        // aria-label="Username"
                        aria-describedby="basic-addon1"
                        onChange={e => SetSetSubject(e.target.value)}
                    />
                    <Image 
                        src={AddImage}
                        roundedCircle
                        width={30}
                        height={30}
                        onClick={setAdd} style={{ cursor: 'pointer', marginLeft: 10 }}
                    />
                </InputGroup>
            </Form>
            { isLoading && (
                <Container className="d-flex flex-column align-items-center justify-content-center" style={{marginBottom:10}}>
                    <Spinner animation="border" role="status" style={{marginBottom:20}}>
                        <span className="visually-hidden">읽는중...</span>
                    </Spinner> 
                </Container>   
            )} 
            { rows.length === 0 && (
                <Container className='flex_center'>
                    <div className=''>
                        홍보셋이 존재하지 않습니다. 추가하려면 제목을 입력하시고 오른쪽 + 버튼을 클릭하세요
                    </div>
                </Container>
            )}           
            <Container style={{marginTop:30}}>
                <Stack gap={3}>
                    { rows.map((row, rowIndex) => (
                        <React.Fragment key={`MKTSET - ${rowIndex}`}>
                        <div>
                            <div className="mb-3 d-flex justify-content-between">
                                <div key={`${rowIndex}-code`} className='flex_row'>
                                    <div className='mainInfoTextB'>
                                        {`https://myshott.co.kr/mkt/${row.mkt_code}`}
                                    </div>
                                    <Image
                                        src={CopyImage}
                                        width={25}
                                        height={25}
                                        style={{marginLeft:10, cursor: 'pointer'}}   
                                        onClick={() => copyClipboard(`https://myshott.co.kr/mkt/${row.mkt_code}`)}
                                    />
                                </div>
                                <Image
                                    src={SubMenu}
                                    width={20}
                                    height={20}
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => handleSubMenuClick(e, row)} // 메뉴 클릭 이벤트에 row 추가
                                />
                            </div>
                            <span>{row.set_subject}</span>
                            <div className='flex_row' style={{marginBottom:15}}>
                                <span className='mainSmallText' style={{marginRight:20}}>액세스카운트:( <span className='mainTitleText'>{row.access_cnt}</span> )</span>
                                <span className='mainSmallText' style={{marginRight:20}}>소셜로그인:( <span className='mainTitleText'>{row.user_id_count}</span> )</span>
                                <span className='mainSmallText' style={{marginRight:20}}>실명인증:( <span className='mainTitleText'>{row.phone_number_count}</span> )</span>
                            </div>
                            <div style={{marginBottom:10}}>
                                {/* <ListUrl urls={ Array.isArray(urlRows[row.mkt_set_id]) ? urlRows[row.mkt_set_id] : [] }/> */}
                                { (Array.isArray(urlRows[row.mkt_set_id]) ? urlRows[row.mkt_set_id] : []).map( (url, urlIndex) => (
                                    <div key={`MKTURL - ${urlIndex}`} className='flex_row'>
                                        <div  className='mainInfoTextB' style={{marginBottom:5}}>{url.mkt_url}</div>
                                        <Image
                                            src={GoLinkImage}
                                            width={25}
                                            height={25}
                                            style={{marginLeft:10, marginRight : 10, cursor: 'pointer'}}
                                            onClick={ () => goUrl(url.mkt_url)}
                                        />
                                        <HoverImage
                                            src={TrashImage}
                                            width="25px"
                                            height="25px"
                                            alt = "항목삭제"
                                            onClick={deleteUrl}
                                            extraArgs={[url.mkt_url, row.mkt_set_id, url.url_id ]}
                                        />                                       
                                    </div>
                                ))}
                                { newUrlIndex !== null && newUrlIndex===rowIndex && (
                                    <InputUrl 
                                        mkt_set_id={row.mkt_set_id}
                                        rowIndex={rowIndex}
                                        baseUrl={newUrl}
                                        onDeleteClicked={hideNewUrl}
                                        onUrlChange={handleNewUrlChange}
                                        title={`새 URL *`}/>
                                ) }
                                <div className='flex_row' style={{marginTop : 10}}>
                                    <Button variant="light" size="sm" style={{marginRight:20}} onClick={() => showNewUrl(rowIndex)}>+ 홍보 URL 추가</Button>
                                    <Button variant="light" size="sm" onClick={() => saveNewUrl(row.mkt_set_id)}>저장</Button>
                                </div>
                            </div>
                            <span className='mainSmallText'>
                                {formatDate(row.create_at)}
                            </span>
                            <hr/>              
                        </div>
                        </React.Fragment>
                    ))}
                </Stack>
            </Container>
            {showMenu && (
                <div 
                    ref={menuRef} // 메뉴 창 참조 설정
                    className='bordered-container'
                    style={{
                        position: 'absolute',
                        top: `${menuPosition.y}px`,
                        left: `${menuPosition.x-80}px`,
                        zIndex: 1000,
                        // 기타 스타일링: 배경색, 테두리 등
                        borderRadius: 10,
                        padding: 15
                    }}
                >
                    <div className='flex_row' onClick={() => handleMenuItemClick('Edit')} style={{marginBottom:10, cursor: 'pointer'}}>
                        <Image
                            src={EditImage}
                            width={25}
                            height={25}
                            style={{marginRight: 10}}
                        />
                        <span>편집</span>
                    </div>
                    <div className='flex_row' onClick={() => handleMenuItemClick('Delete')} style={{cursor: 'pointer'}}>
                        <Image
                            src={DeleteImage}
                            width={25}
                            height={25}
                            style={{marginRight: 10}}
                        />
                        <span>삭제</span>
                    </div>                   
                </div>
            )}            
        </Container>
    );

}

export default SetHome;