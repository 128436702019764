import React, { useEffect } from 'react';
import { useEnv } from './AppContext.js';
import { LoadFromSystem } from './Global.js';
import { useLoginProc } from './LoginProc2.js';

function GlobalAppStatus() {
    const { ENV } = useEnv();
    const { loginProc } = useLoginProc();

    useEffect(() => {
        // async 함수를 정의하고 즉시 호출합니다.
        (async () => {
          try {
            //const sysData = await LoadFromSystem();
            // 컴포넌트가 여전히 마운트 상태인 경우에만 상태를 설정하거나 로깅을 수행합니다.
            //console.log(ENV);
            if(!ENV || ENV.user_id===null) {
              const sysData = await LoadFromSystem();
              const newENV = {};
              if(sysData && sysData.user_id ) {
                  newENV.user_id = sysData.user_id;
                  newENV.last_login_type =sysData.last_login_type;
                  await loginProc(newENV);
              }            
            }
          } catch (e) {
            // 오류를 적절하게 핸들링합니다. (예: 로깅, 사용자에게 메시지 표시 등)
            console.error(e);
          }
        })();
      }, [ENV.user_id]); 

      return null;
}

export default GlobalAppStatus;