import React, {useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Image, Form } from 'react-bootstrap';
import CloseImage from '../../assets/close.png';
import { useEnv } from '../AppContext';
import { ExecuteSql } from '../api';

function PostEdit() {
    const location = useLocation();
    const data = location.state?.data;
    const navigate = useNavigate();
    const [ subject, setSuject ] = useState('');
    const [ content, setContent ] = useState('');
    const formRef = useRef(null);
    const { ENV, setEnv} = useEnv();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if(data?.mkt_subject) {
            setSuject(data.mkt_subject);
        }
        if( data?.mkt_content ) {
            setContent(data.mkt_content);
        }
    }, []);    

    // subject 또는 content가 변경될 때 실행
    useEffect(() => {
        if (subject !== data?.mkt_subject || content !== data?.mkt_content) {
            setIsChanged(true); // 하나라도 변경되면 변화 있음
        } else {
            setIsChanged(false); // 둘 다 변경되지 않으면 변화 없음
        }
    }, [subject, content, data?.mkt_subject, data?.mkt_content]);

    // .container 스타일을 인라인 스타일로 변환
    const containerStyle = {
        display: 'flex',
        alignItems: 'center', // "아이템들을 컨테이너의 가운데에 정렬"
        justifyContent: 'space-between', // "아이템들 사이에 균등한 간격을 둠"
        width: '100%', // "혹은 부모 요소에 따라 조정"
    };

    // .text 스타일을 인라인 스타일로 변환
    const subjectStyle = {
        flexGrow: 1, // "나머지 공간을 모두 차지하도록 설정"
        margin: '0 20px', // "좌우 이미지와의 간격 조정"
    };

    function goBack() {
        navigate(-1);
    }

    // 이미지 클릭 이벤트 핸들러
    const handleSubmitClick = async (event) => {
        event.preventDefault(); // 기본 이벤트 동작 방지
        // console.log(data.content_id);
        // console.log('제목:', subject);
        // console.log('내용:', content);
        try {
             // 앞뒤 공백을 제거하고 내용이 있는지 검사
            if (!subject.trim() || !content.trim()) {
                alert('제목과 내용을 모두 입력해주세요.');
                return; // 하나라도 비어 있으면 함수를 여기서 종료
            }        
            
            if(data && !isChanged ) {
                alert('제목, 내용 변화가 없습니다');
                return;
            }

            let sql;
            let params;
            if( data && data.content_id) {
                sql = 'update t_mkt_content set ? where content_id=?';
                params = [
                    { 
                        mkt_subject : subject,
                        mkt_content : content
                    },
                    data.content_id
                ];
            } else {
                sql = 'insert into t_mkt_content set ?';
                params = {
                    mkt_user_id : ENV.mkt_user_id,
                    mkt_subject : subject,
                    mkt_content : content                   
                }
            }
            const ret = await ExecuteSql(sql, params);
            if( ret.success ) {
                const newENV = { ...ENV };
                newENV.post_edit_complete = true;
                setEnv(newENV);      
                navigate(-1);
            }
        } catch(e) {
            console.log(e);
        }
    };

    return (
        <Container >
            <Form ref={formRef}>
                <div style={containerStyle}>
                    <Image
                        src={CloseImage}
                        width={25}
                        height={25}
                        style={{ cursor: 'pointer' }}
                        onClick={goBack}
                    />
                    <div style={subjectStyle}></div>
                    <span style={{width: 50, cursor: 'pointer'}} onClick={handleSubmitClick}>완료</span>
                </div>
                <hr/>            
                <Form.Group className="mb-3" controlId="post.subject">
                    <Form.Label>제목</Form.Label>
                    <Form.Control type="text" placeholder="제목을 입력하세요" 
                        value={subject}
                        onChange={e => setSuject(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="post.body">
                    <Form.Label>내용</Form.Label>
                    <Form.Control as="textarea" rows={20} 
                        value={content}
                        onChange={e => setContent(e.target.value)}/>
                </Form.Group>
            </Form> 
        </Container>       
    );
}

export default PostEdit;