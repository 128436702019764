import { useEnv } from './AppContext';
import { GetRow } from './api';
import { useModal } from './ShowModal';

export const useLoginProc = () => {
    const { setEnv } = useEnv();
    const { showModal } = useModal();

    const loginProc = async (newENV) => {
        try {
            // setEnv({
            //     ...ENV,
            //     ...newENV
            // });
            let checked_mkt = false;
            if( newENV.user_id !== null) {
              //console.log('user_id', newENV.user_id);
              const ret = await GetRow('select user_name,checked_mkt from t_user where user_id=?', [newENV.user_id]);
              if( ret.success ) {
                checked_mkt = ret.data?.checked_mkt;
                newENV.checked_mkt = checked_mkt;
                newENV.user_name = ret.data?.user_name;
                //console.log(newENV.checked_admin);
                const ret2 = await GetRow('select mkt_user_id, checked_admin from t_mkt_user where user_id=?', [newENV.user_id]);
                if( ret2.success) {
                  newENV.mkt_user_id = ret2.data.mkt_user_id;
                  if( ret2.data.checked_admin === 1) {
                    newENV.checked_admin = true;
                  }
                }
              }
            }   
            setEnv(prevEnv => ({
              ...prevEnv,
              ...newENV
            }));            
            return checked_mkt;
          } catch(e) {
            console.log('LoginProc error', e);
            //Alert.alert('LoginProc error', e)
          }        
    }

    return { loginProc };
};