import React, { useState, useEffect} from 'react';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { routerServer } from './Global';
import { useLoginProc } from './LoginProc2';
import { Container, Spinner } from 'react-bootstrap';
import AlertMessage from './AlertMessage';

function Oauth2() {
    const navigate = useNavigate();
    const redirect_uri = `${window.location.origin}/oauth/callback`;
    const { loginProc } = useLoginProc();
    const [ authFailed, setAuthFailed] = useState(false);

    const social = {
        'kakao' : {
            client_id : "90d60a0115ad5aa6b005532d9f7ea2ae",
            tokenEndpoint : "https://kauth.kakao.com/oauth/token",
            profile_url : `${routerServer}/kakao/kakao_profile`
        },
        'naver' : {
            client_id : "RT6jhR9HQJ3ZADONhWfZ",
            tokenEndpoint : "https://nid.naver.com/oauth2.0/token",
            profile_url : `${routerServer}/naver/naver_profile`
        },
   
    };

    const newENV = {
        user_id : null,
        last_login_type : null
    }    

    const handleError = (error) => {
        console.log(error);
        //showToast('오류가 발생하였습니다');
        navigate('/home');
    };    

    const navigateAfterLogin = async () => {
        //console.log( states.newENV);
        if( newENV.user_id) {
            const ret = await loginProc(newENV);
            // console.log(ret);
            if( ret ) {
                navigate('/home');
            } else {
                setAuthFailed(true);
            }
        } 
    //   let target;
    //   if( target_after_login ) {
    //     target = target_after_login;
    //   } else if(target_after_login2) {
    //     target = target_after_login2;
    //   } else {
    //     target = getTargetAfterLogin();
    //   }
        //console.log('로그인 후 이동 :', target , ', user_id : ', newENV.user_id );
        //navigation.navigate(target, {login_before : true}); 
       
    };    

    const rcvLast = (access_token, state) => {
        //console.log('Called rcvLast');
        //console.log(access_token);
        Axios.get(`${social[state].profile_url}/${access_token}`, { params: {} })
          .then(({ data }) => {
            if ( data.user_id) {
                newENV.user_id = data.user_id;
                newENV.last_login_type = data.last_login_type;               
            } else {
                const _msg = {
                  body : '마이샷 정보를 확인 할 수 없습니다'
                }
                //showModalMessage(_msg);             
                return Promise.resolve();
            }
            navigateAfterLogin();
           })
           .catch(handleError);
    };    
    
    async function fetchAccessToken_kakao(code, codeVerifier, state) {
        try {
          const response = await fetch(social[state].tokenEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              grant_type: 'authorization_code',
              client_id: social[state].client_id,
              redirect_uri: redirect_uri,
              code: code,
              code_verifier: codeVerifier, // PKCE 코드 검증자 추가
            }),
          });
          const data = await response.json();
          //console.log('access_token:', data.access_token);
          if( data.access_token ) {
            rcvLast(data.access_token, state);
          }
        } catch (error) {
          console.error('Error fetching access token:', error);
        }
    }   

    async function fetchAccessToken_naver(code, codeVerifier, state) {
        try {
            const response = await fetch(`${routerServer}/naver/get-naver-token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    code: code,
                    codeVerifier: codeVerifier,
                    redirect_uri: redirect_uri,
                }),
            });
            const data = await response.json();
            // console.log('access_token:', data.access_token);
            if( data.access_token ) {
                rcvLast(data.access_token, state);
            }
        } catch (error) {
          console.error('Error fetching access token:', error);
        }
    }  
    
    async function fetchAccessToken_google(code, codeVerifier, state) {
        try {
            const params = new URLSearchParams({
                client_id: '253648496299-d571bcrgi10guakq0mi1e5f45n04f6pr.apps.googleusercontent.com',
                code: code,
                client_secret: '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',
                grant_type: 'authorization_code',
                redirect_uri: redirect_uri,
            });
    
            const response = await fetch(`https://oauth2.googleapis.com/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params.toString(), // URLSearchParams 객체를 문자열로 변환
            });
            const data = await response.json();
            // console.log(data);
            //console.log('access_token:', data.access_token);
            if( data.access_token ) {
                const response2 = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${data.access_token}`,
                      'Content-Type': 'application/json'
                    }
                })
                const data2 = await response2.json();
                // console.log(data2);
                if( data2.id) {
                    const response3 = await fetch(`${routerServer}/google/set_profile`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          google_id : data2.id,
                          user_name : data2.name,
                          email : data2.email
                        }),
                    });      
                    const data3 = await response3.json();
                    // console.log(data3);
                    if ( data3.user_id) {
                        newENV.user_id = data3.user_id;
                        newENV.last_login_type = data3.last_login_type;      
                    } 
                    navigateAfterLogin();                                        
                }
            }
        } catch (error) {
          console.error('Error fetching access token:', error);
        }
    }     

    useEffect(() => {
        //console.log(window.location);
        const { code, code_verifier, state } = queryString.parse(window.location.search);
        // 액세스 토큰을 사용하여 사용자 정보를 가져오거나 로그인 상태를 설정
        // console.log(code, state);
        if( code ) {
            //console.log('code_verifier', code_verifier);
            if( state === 'kakao')
                fetchAccessToken_kakao(code, code_verifier, state);
            else if(state === 'naver') 
                fetchAccessToken_naver(code, code_verifier, state);
            else if(state === 'google') {
                // const idTokenPayload = decodeIdToken(code); // yourIdToken을 실제 토큰으로 대체하세요.
                // //console.log(idTokenPayload)
                // const _google = {
                //   google_id : idTokenPayload.sub,
                //   user_name : idTokenPayload.name,
                //   email : idTokenPayload.email
                // }
                fetchAccessToken_google(code, code_verifier, state);
                         
            }
        }
    }, []);

    return (
        <Container>
            { authFailed && (
                <AlertMessage
                    title='로그인 실패'
                >
                    마케팅 관리 프로그램 권한을 확인하세요
                </AlertMessage>
            )}
            { authFailed === false && (
                <Container className="d-flex justify-content-center" style={{ width: '100%', height: '65vh'}}>
                    <Container className="d-flex flex-column align-items-center justify-content-center">
                        <Spinner animation="border" role="status" style={{marginBottom:20}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> 
                        <p className=''>로그인 처리중입니다. 기다려주세요...</p>           
                    </Container>
                </Container>
            )}           
        </Container>
    );
}

export default Oauth2;