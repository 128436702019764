import React, { createContext, useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Context 생성
const ModalContext = createContext();

// 모달을 보여주고 숨기기 위한 함수를 제공하는 Hook
export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}

// 모달의 상태와 로직을 관리하는 Provider 컴포넌트
export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({ show: false });

  // 모달을 보여주는 함수
  const showModal = (props) => {
    setModalProps({ ...props, show: true });
  };

  // 모달을 숨기는 함수
  const hideModal = () => {
    setModalProps({ show: false });
  };

  const value = { showModal, hideModal };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modalProps.show && (
        <ShowModal
          title={modalProps.title}
          children={modalProps.children}
          afterOK={modalProps.afterOK}
          okTitle={modalProps.okTitle}
          onHide={hideModal}
        />
      )}
    </ModalContext.Provider>
  );
};

// 모달 컴포넌트
function ShowModal({ children, title, afterOK, okTitle = "확인" }) {
  const { hideModal } = useModal(); // Context로부터 hideModal 함수 사용

  // afterOK 함수를 호출한 다음 모달을 숨김
  const handleOK = () => {
    if (afterOK) {
      afterOK();
    }
    hideModal();
  };

  return (
    <Modal show={true} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>닫기</Button>
        { afterOK && (
            <Button variant="primary" onClick={handleOK}>
                {okTitle}
            </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

// ModalContext를 사용하는 외부 컴포넌트에서는 ModalProvider로 컴포넌트를 감싸고,
// useModal Hook을 통해 showModal 함수에 접근하여 모달을 동적으로 표시할 수 있습니다.
