import React from 'react';
import { Container } from 'react-bootstrap';
import KakaoIcon from '../assets/images/kakao_icon.png';
import NaverIcon from '../assets/images/naver_icon_w.png';
import GoogleIcon from '../assets/images/google.png';

const KAKAO_CLIENT_ID = "90d60a0115ad5aa6b005532d9f7ea2ae";
const NAVER_CLIENT_ID = "RT6jhR9HQJ3ZADONhWfZ";
const GOOGLE_CLIENT_ID = "253648496299-d571bcrgi10guakq0mi1e5f45n04f6pr.apps.googleusercontent.com";
//const GOOGLE_CLIENT_ID = "481792183278-o28e88052n24c1j76cb0bubpu5e643gd.apps.googleusercontent.com";

function LoginPage() {
    const redirect_uri = `${window.location.origin}/oauth/callback`;

    //console.log(window.location.origin);

    const kakaoLogin = () => {
        const scope = '';
        const oauthUrl = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scope}&state=kakao`;
        //window.open(oauthUrl, '_blank', 'width=500,height=600');
        window.location.href = oauthUrl;
    }

    const naverLogin = () => {
        const scope = '';
        const oauthUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scope}&state=naver`;
        window.location.href = oauthUrl;
    }

    const googleLogin = () => {
        const scope = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
        const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${redirect_uri}&scope=${scope}&state=google`;
        window.location.href = oauthUrl;
    }

    return (
        <Container className="d-flex align-items-center justify-content-center" style={{ height: '60vh' }}>
            <Container className="d-flex flex-column align-items-center" style={{ width: '100%' }}>
                <div style={{ alignItems: 'center', marginBottom: 50, display: 'flex', flexDirection: 'column', width: '100%' }}> 
                    <p>로그인을 진행해 주세요</p>
                </div>
                {/* {LastLoginType() !== null && (
                    <Container className="d-flex justify-content-center" style={{ width: '100%' }}>
                        <span>최근 {LastLoginType()}을 이용하셨습니다</span>
                    </Container>
                )} */}
                <Container className="d-flex justify-content-center" style={{ width: '100%', marginBottom: 20 }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#fee500', borderRadius: 5, width: '75%', cursor: 'pointer' }} onClick={kakaoLogin}>
                        <div style={{ flexDirection: 'row', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                            <img src={KakaoIcon} alt="Kakao" style={{ height: 25, width: 25, marginRight: 10 }} />
                            <span className="mainTitleText">카카오로 시작하기</span>
                        </div>
                    </div>
                </Container>
                <Container className="d-flex justify-content-center" style={{ width: '100%', marginBottom: 20  }}>
                    <div className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#1ec502', borderRadius: 5, width: '75%', cursor: 'pointer' }} onClick={naverLogin}>
                        <div style={{ flexDirection: 'row', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                            <img src={NaverIcon} alt="Kakao" style={{ height: 25, width: 25, marginRight: 10 }} />
                            <span className="mainTitleText" style={{color: '#f1f3f7'}}>네이버로 시작하기</span>
                        </div>
                    </div>
                </Container>
                <Container className="d-flex justify-content-center" style={{ width: '100%' }}>
                    <div className="d-flex align-items-center justify-content-center" style={{border: '1px solid #8F8C83', borderRadius: 5, width: '75%', cursor: 'pointer' }} onClick={googleLogin}>
                        <div style={{ flexDirection: 'row', padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                            <img src={GoogleIcon} alt="Kakao" style={{ height: 25, width: 25, marginRight: 10 }} />
                            <span className="mainTitleText">구글로 시작하기</span>
                        </div>
                    </div>
                </Container>
            </Container>
        </Container>
    );
}

export default LoginPage;