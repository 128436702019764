import React, {useState} from 'react';
import { Container, Button, Form, InputGroup } from 'react-bootstrap';
import SqlTable from '../SqlTable';
import { GetRow, ExecuteSql } from '../api';
import { useModal } from '../ShowModal';
import { useNavigate } from 'react-router-dom';

function AddUser() {
    const [ phoneNumber, setPhoneNumber] = useState('');
    const { showModal } = useModal();
    let navigate = useNavigate();
    const [ userSql, setUserSql] = useState('');

    function handleNumberInput(event) {
        const value = event.target.value;
        // 여기에서 입력 값에 대한 추가적인 유효성 검사를 수행할 수 있습니다.
        setPhoneNumber(value);
    }    

    async function afterOK(data) {
        try {
            const ret = await ExecuteSql('insert into t_mkt_user set user_id=?', [data.user_id]);
            if( ret.success ) {
                const ret2 = await ExecuteSql('update t_user set checked_mkt=true where user_id=?', [data.user_id]);
                if( ret2.success ) {
                    showModal({
                        title : '회원 정보 추가 성공',
                        children : '마케터 회원 정보가 추가되었습니다'
                    });
                }
                //navigate('./manage_user');
            } else {

            }
        } catch(e) {
            console.log(e);
        }
    }

    // Submit 버튼 클릭 시 실행될 함수
    const handleSubmit = async (user) => {
        //console.log('입력된 전화번호:', phoneNumber);
        try {
            const ret = await GetRow('select user_id,user_name,phone_number from t_user where user_id=?', [user.user_id]);
            if( ret.success && ret.data !== null) {
                //console.log(ret.data);
                showModal({
                    title : '회원 정보를 확인하세요',
                    children : (
                        <Form>
                            <fieldset disabled>
                                <Form.Group className="mb-3">
                                    <Form.Label>이름</Form.Label>
                                    <Form.Control id="disabledTextInput" placeholder={ret.data.user_name} />
                                </Form.Group>                                
                                <Form.Group className="mb-3">
                                    <Form.Label>전화번호</Form.Label>
                                    <Form.Control id="disabledTextInput" placeholder={ret.data.phone_number} />
                                </Form.Group> 
                                <Form.Group className="mb-3">
                                    <Form.Label>Social</Form.Label>
                                    <Form.Control id="disabledTextInput" placeholder={user.last_login_type} />
                                </Form.Group>                                                                                             
                            </fieldset>
                        </Form>
                    ),
                    afterOK : () => afterOK(ret.data)
                });
            } else {
                showModal({
                    title : '회원 검색 실패',
                    children : (
                        <div>
                            <p>{`[${phoneNumber}]에 대한 마이샷 회원 정보가 존재 하지 않습니다`}</p>
                            <p>전화번호를 확인해 주세요</p>
                        </div>
                    )
                });
            }
        } catch(e) {
            console.log(e);
        }
    };

    const ClickedRow = (row) => {
        //console.log(row);
        handleSubmit(row);
    }  

    const submitSql = (event) => {
        event.preventDefault(); 
        const sql = `select user_id,user_name,last_login_type from t_user where phone_number='${phoneNumber}'`;
        setUserSql(sql);
    }

    return (
        <React.Fragment>
            <Container  style={{marginBottom : 10}}>
                <Form>
                    <Form.Label>추가할 마케터의 전화번호 입력</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">전화번호</InputGroup.Text>
                        <Form.Control
                            type="tel" 
                            pattern="[0-9]{3}[0-9]{4}[0-9]{4}"
                            required
                            placeholder="'-' 없이 전화번호를 입력하세요"
                            // aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleNumberInput}
                        />
                        <Button type="submit" variant="outline-secondary" onClick={submitSql}>확인</Button>
                    </InputGroup>
                    <Form.Text className="text-muted">
                        마이샷에서 가입하고 실명인증된 전화번호를 입력하셔야 합니다
                    </Form.Text>                
                </Form>
            </Container>
            { userSql !== '' && (
                <Container key={userSql}>
                    <Form.Text className="text-muted">
                            사용중인 소셜사이트중에서 선택하세요
                    </Form.Text>               
                    <SqlTable
                        sql = {userSql}
                        column_names={{
                            user_id : 'User',
                            user_name : '이름',
                            last_login_type : 'Socail'
                        }}
                        // do_paging = {true}
                        onRowClick = { (row) => ClickedRow(row)}
                    />
                </Container>
            )}
        </React.Fragment>
    );    
}

export default AddUser;