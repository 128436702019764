import React, { useState, useEffect } from 'react';
import './App.css';
import { Container, Nav, Button, Badge, NavDropdown  } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate  } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import { useEnv, useAuth } from './components/AppContext.js';
import Oauth2 from './components/Oauth2';
import GlobalAppStatus from './components/GlobalAppStatus';
import { RemoveSystemData } from './components/Global';
import Home from './components/Home';
import ManagerUser from './components/Setting/ManageUser';
import AddUser from './components/Setting/AddUser.js';
import PostHome from './components/Mkt/PostHome.js';
import PostView from './components/Mkt/PostView';
import PostEdit from './components/Mkt/PostEdit';
import SetHome from './components/Mkt/SetHome';

function CustomTab({ title, path, status }) {
  let navigate = useNavigate();
  let location = useLocation();
  const isActive = location.pathname === path;
  return (
    <Nav.Item>
      <Nav.Link
        className={isActive ? 'active-bold' : ''}
        onClick={() => navigate(path)}
        style={{ cursor: 'pointer' }}
        active={isActive}
        disabled = {status==='disabled' ?  true : false}
      >
        {title}
      </Nav.Link>
    </Nav.Item>
  );
}

function LoginButton() {
  const navigate = useNavigate();

  function handleLogin() {
    navigate('/login');
  }

  // useEffect(() => {
  //   handleLogin();
  // }, [])  

  return (
    <Button variant="primary btn-sm" className="custom-login-btn" onClick={handleLogin}>
      로그인
    </Button>
   );
}

function LogoutButton() {
  const { ENV, setEnv  } = useEnv();
  const navigate = useNavigate();
  
  async function handleLogout() {
    const newENV = { ...ENV };
    newENV.user_id = null;
    newENV.last_login_type = null;
    newENV.checked_mkt = null;
    newENV.checked_admin = false;
    setEnv(newENV); 
    await RemoveSystemData();
    navigate('/home');
  }

  return (
    <React.Fragment>
      <div className='flex_row'>
        { ENV.checked_admin && (
          <Badge pill bg="success" style={{marginRight: 10}}>
            관리자
          </Badge>
        )}
        <Button variant="primary btn-sm" className="custom-login-btn" onClick={handleLogout}>
          로그아웃
        </Button>
      </div>
    </React.Fragment>
  );  
}

function SettingDropMenu() {
  const navigate = useNavigate();
  const { ENV } = useEnv();

  if( ENV.checked_admin !== true ) return null;

  return (
    <NavDropdown title="관리" id="nav-dropdown"> 
      <NavDropdown.Item eventKey="4.1" onClick={() => navigate('/manage_user') }>마케터 관리</NavDropdown.Item>
      <NavDropdown.Item eventKey="4.2" onClick={() => navigate('/add_user') }>마케터 추가</NavDropdown.Item>
    </NavDropdown>   
  );
}

function App(props) {
  const { isLoggedIn } = useAuth();
  const { ENV } = useEnv();

  return (
    <Router>
      <GlobalAppStatus/>
      <Container>
        {/* 'd-flex'와 'justify-content-between' 클래스를 추가하여 탭과 로그인 버튼을 양 끝으로 정렬합니다. */}
        <Nav variant="tabs" className="mb-3 d-flex justify-content-between">
          {/* 기존의 탭들 */}
          <div className="d-flex">
            <CustomTab title={ENV.user_name ? `#(${ENV.user_name})` : 'Home'} path="/" />
            <CustomTab title="홍보셋" path="/set_home" />
            <CustomTab title="홍보글" path="/post_home" />
            <SettingDropMenu/>
          </div>
          {/* 로그인 버튼 */}
          { isLoggedIn ? (
            <LogoutButton />
          ) : (
            <LoginButton/>
          )}
        </Nav>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/manage_user" element={<ManagerUser/>} />
          <Route path="/add_user" element={<AddUser/>} />

          <Route path="/post_home" element={<PostHome/>} />
          <Route path="/post_view" element={<PostView/>} />
          <Route path="/post_edit" element={<PostEdit/>} />

          <Route path="/set_home" element={<SetHome/>} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/oauth/callback" element={<Oauth2 />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
