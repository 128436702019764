import React from 'react';

export const routerServer = 'https://myshott.kr';
//export const routerServer = 'https://myshott.co.kr';
//export const routerServer = 'http://iztel.iptime.org:7777';
//export const routerServer = 'http://myshott.chyh.kr:7777';
//export const routerServer = 'https://myshott.com';
//export const routerServer = 'https://my.chyh.kr';
export const appVersion = '1.0.0';

export async function  SaveToSystem(sysData)
{
    ///console.log('Before save : ', sysData.user_id);
    try {
        const stringifiedData = JSON.stringify(sysData);
        localStorage.setItem('mkt_sys_data', stringifiedData);
    } catch (e) {
        console.error('Saving error: ', e);
    }
    //console.log('Before save : ', sysData.user_id);
}

export async function LoadFromSystem()
{
    try {
        let sysDataString;
        sysDataString = localStorage.getItem('mkt_sys_data');
        return JSON.parse(sysDataString);
    } catch(e) {
        console.error('Loading error: ', e);
        return null; 
    }
}

export async function RemoveSystemData()
{
    try {
        localStorage.removeItem('mkt_sys_data');
    } catch(e) {
        console.error('Deleting error: ', e);
        return null; 
    }
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
}