import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider, EnvProvider } from './components/AppContext.js';
import { ModalProvider } from './components/ShowModal.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <EnvProvider>
        <ModalProvider>
          <App />
        </ModalProvider>
      </EnvProvider>
    </AuthProvider>
  </React.StrictMode>  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
