import React, { useState, useEffect } from 'react';
import { SaveToSystem, } from './Global';

const AuthContext = React.createContext();
const EnvContext = React.createContext();

export const EnvProvider = ({ children }) => {
    const [ENV, setEnv] = useState({
        login_type: null,
        user_id : null,
        checked_mkt : null,
        checked_admin : false,
        mkt_user_id : null,
        user_name : null,
        post_edit_complete : false
    });
    const { login, logout } = useAuth();
    const [envLoadCompleted, setEnvLoadCompleted] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                if(envLoadCompleted) {
                    //console.log('ENV user_id: ', ENV.user_id);
                    if(ENV.user_id != null && ENV.checked_mkt === 1) {
                        login();
                    } else {
                        logout();
                    }
                    await SaveToSystem(ENV);
                } else {
                    setEnvLoadCompleted(true);
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }            
        })();        
    }, [ENV.user_id]); 

    return (
        <EnvContext.Provider value={{ ENV, setEnv }}>
         {children}
        </EnvContext.Provider>
    );
};

export function useEnv() {
    return React.useContext(EnvContext);
}

export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // useEffect(() => {
    //     console.log('isLoggedIn:', isLoggedIn);
    // }, [isLoggedIn]);    
  
    const login = () => {
      setIsLoggedIn(true);
    };
  
    const logout = () => {
      setIsLoggedIn(false);
    };
  
    return (
      <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
}

export function useAuth() {
    return React.useContext(AuthContext);
}